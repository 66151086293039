<template>
  <v-row justify="center">
    <v-dialog :value="true" persistent max-width="480px" transition="dialog-transition" @input="$emit('close-dialog')">
      <v-card>
        <v-form @submit.prevent="create">
          <v-card-title>
            <v-list-item class="pa-0">
              <v-list-item-content>
                <v-list-item-title v-text="$t('team.createTeamDialog.newTeam')" />
              </v-list-item-content>
              <v-spacer />
              <v-list-item-action>
                <v-btn icon color="black" @click="$emit('close-dialog')">
                  <v-icon>close</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-card-title>
          <v-card-text>
            <cohort-field
              v-model="form.cohortId"
              :error-messages="getFieldErrors('cohortId')"
            />
            <v-text-field
              v-model="form.suffix"
              :error-messages="getFieldErrors('suffix')"
              :label="$t('team.fields.name')"
              outlined
              dense
              @blur="$v.form.suffix.$touch()"
            />
          </v-card-text>
          <v-card-actions class="px-6 pb-5 pt-0 justify-space-around align-center">
            <v-btn color="primary" small depressed width="120" type="submit" v-text="$t('team.actions.create')" />
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import formUtils from '@/utils/mixins/formUtils'

export default {
  name: 'CreateTeamDialog',
  components: {
    CohortField: () => import('@/components/formFields/CohortField'),
  },
  mixins: [formUtils],
  props: {
    organizationId: { type: String, required: true },
    projectId: { type: String, required: true },
    clubId: { type: String, required: true },
  },
  data() {
    return {
      form: {
        cohortId: null,
        suffix: null,
      },
    }
  },
  validations() {
    return {
      form: {
        cohortId: { required },
        suffix: { },
      },
    }
  },
  methods: {
    async create() {
      const { organizationId, projectId, clubId, form } = this
      if (!this.isFormValid()) return
      await this.runAsync(async () => {
        await this.$store.dispatch('team/create', { organizationId, projectId, clubId, data: form })
        await this.$store.dispatch('club/updateByClubmanager', { organizationId, projectId, updatedType: 'teams' })
        this.$emit('close-dialog')
      })
    },
  },
}
</script>
